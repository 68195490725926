import React from 'react';
import axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Loader from 'react-loader';
import Select from 'react-select';
import { CommentGroup } from '../../../../../enums/CommentGroup';
import { DiagnosisInitiatorType } from '../../../../../enums/DiagnosisInitiatorType';
import { LineItemReviewStatus } from '../../../../../enums/LineItemReviewStatus';
import { MedicalRecordType } from '../../../../../enums/MedicalRecordType';
import { ProviderType } from '../../../../../enums/ProviderType';
import { DiagnosisUtils } from '../../../../../utils/DiagnosisUtils';
import { formatISODateOnly, getDifference }  from '../../../../../utils/DateUtils';
import { dateOfServiceValidation, combinedPageNumValidations, requiredFieldValidation,
    requiredFieldObjectValidation } from '../../../../../utils/ValidationUtils';
import AlertDialog from '../../../../Shared/AlertDialog';
import EvidenceUploadModal from './EvidenceUploadModal';
import ProviderTypeaheadSearchInput from '../../../../Shared/ProviderTypeaheadSearchInput';
import ProviderSearchModal from '../../../../Shared/ProviderSearchModal';
import RADVFormModal from './RADVFormModal';

class DiagnosisFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.defaultDiagSaveObj = {
            providerTypeID: '',
            dateOfServiceFrom: '',
            dateOfServiceTo: '',
            sourceID: '',
            medicalRecordTypeID: '',
            medicalRecordImageID: '',
            diagnosisComments: [],
            evidenceImageID: '',
            pages: '',
            pageNumFrom: '',
            pageNumTo: '',
            rafTypeID: '',
            selectedProvider: null,
            selectedSecondaryProvider: null,
            userID: '',
            //medicalRecordDiagID: '',
            claimNumber: '',
            claimLinkID: '',
            claimMatchType: '',
            claimsRankingDetailID: ''
        };
        this.defaultDiagDetailsSaveObj = {
            diagnosisCD: '',
            description: '',
            hccPartCs: '',
            hccPartDs: '',
            claimNumber: '',
            claimLinkID: '',
            claimMatchType: '',
            claimsRankingDetailID: '',
            pages: '',
            pageNumFrom: '',
            pageNumTo: '',
        };

        this.state = {
            // diagSaveObjDefaults stores selections that are sticky/pre-populated with data
            // from other diag add activities during the parent component's lifetime
            diagSaveObjDefaults: { ...this.defaultDiagSaveObj },
            ...this.defaultDiagSaveObj,
            diagDetails: [{...this.defaultDiagDetailsSaveObj}],
            disableDOSThru: false,
            commentsDropdownValues: [],
            validationErrorMessages: [],
            postValidationErrorMessages: [],
            visible: false,
            showConfirmModal: false,
            showEvidenceModal: false,
            showProviderModal: false,
            showSecondaryProviderModal: false,
            showRADVModal: false,
            diagLoaded: true,
            lastOpenedImage: null,
            inputTouched: false,
            claimsProviderInputSet: false,
            showClaimsDiagGeneration: false,
            claimsDiagGenerationCount: 0,
            loadingClaimLink: false,
            initialSetupFinished: false,
            isOpenForEdit: false
        };

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
        this.validationFields = ["providerMasterID", "providerTypeID", "dateOfServiceFrom", "dateOfServiceTo",
        "diagnosisCD", "sourceID", "medicalRecordImageID", "pages", "medicalRecordTypeID", "hccHierarchy", "radvScore"];
        this.confirmModalMessage = 'Are you sure you want to cancel this record?';
        this.datepickerFrom = null;
        this.datepickerTo = null;
        this.minDateOfService = "2012-01-01T12:00:00.000Z";
        this.lineOfBusiness = localStorage.getItem("lineOfBusiness");

        //todo - move to mrr-ws config; change from 30 to 3
        this.claimRangePadding = 30;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedDiagLookupCD && this.state.diagDetails[0].diagnosisCD !== nextProps.selectedDiagLookupCD) {
            this.setState({
                diagLoaded: false,
                diagDetails: [{diagnosisCD: nextProps.selectedDiagLookupCD, description: '', hccPartCs: '', hccPartDs: '' }],
                dateOfServiceFrom: nextProps.selectedDiagLookupDOS,
                dateOfServiceTo: nextProps.selectedDiagLookupDOS
            }, () => this.diagnosisLookup({ target: { value: nextProps.selectedDiagLookupCD } }, 0));
        }
        //todo: handle initialSetupFinished
        if (nextProps.visible && !this.state.initialSetupFinished)
        {
            //todo: create distinct paths for add, edit, and alert add flows (lot of redundant work here)
            this.setState({
                visible: true,
                //editQABool: nextProps.editQABool
            }, () => {
                this.initialSetup(nextProps);
                this.mapDiagnosisComments();
            });
        }
        else if (nextProps.visible !== this.state.visible) {
            this.setState({ visible: nextProps.visible });
        }

        if (nextProps.editModalRow && nextProps.editModalRow !== this.props.editModalRow) {
            !nextProps.editModalRow.viaAlert ?
                this.populateEditFields(nextProps.editModalRow, nextProps.selectedProvider,
                    nextProps.selectedSecondaryProvider)
                : this.populateAlertAddDiagFields(nextProps.editModalRow, nextProps.selectedProvider,
                    nextProps.selectedSecondaryProvider);
        }
    }

    componentDidMount() {
        window.addEventListener("focus", () => this.handleImagePrePopulation());
    }

    componentWillUnmount() {
        window.removeEventListener("focus", () => this.handleImagePrePopulation());
    }

    componentDidUpdate(prevProps, prevState) {
        // perform invalid date validation on datepicker input text (clear if invalid)
        if (this.datepickerFrom && this.datepickerFrom.refs.input.props.value && isNaN(new Date(this.datepickerFrom.refs.input.props.value))) {
            this.clearDateField('dateOfServiceFrom');
        }
        else if (this.datepickerTo && this.datepickerTo.refs.input.props.value && isNaN(new Date(this.datepickerTo.refs.input.props.value))) {
            this.clearDateField('dateOfServiceTo');
        }
        if(!this.props.editModalRow && prevProps.editModalRow){
            this.setState({isOpenForEdit: false});
        }
        else if(this.props.editModalRow && !prevProps.editModalRow){
            this.setState({isOpenForEdit: true});
        }
    }

    initialSetup = (nextProps) => {
        let medicalRecordType;
        if (this.state.medicalRecordTypeID) {
            //current / user-set value
            medicalRecordType = this.state.medicalRecordTypeID;
        }
        else if (this.state.diagSaveObjDefaults.medicalRecordTypeID) {
            //last added review type value
            medicalRecordType = this.state.diagSaveObjDefaults.medicalRecordTypeID;
        }
        else {
            //review type value
            medicalRecordType = this.props.medicalRecordTypeID.toString();
        }

        let rafType;
        if (this.state.rafTypeID) {
            //current / user-set value
            rafType = this.state.rafTypeID;
        }
        else if (this.state.diagSaveObjDefaults.rafTypeID) {
            //last added raf type value
            rafType = this.state.diagSaveObjDefaults.rafTypeID;
        }
        else {
            // set RAF Type to A
            rafType = "901";
        }

        let selectedProvider;
        if (this.state.selectedProvider) {
            //current / user-set value
            selectedProvider = this.state.selectedProvider;
        }
        else if (this.state.diagSaveObjDefaults.selectedProvider) {
            //last added provider value
            selectedProvider = { ...this.state.diagSaveObjDefaults.selectedProvider };
        }
        else if (nextProps && !nextProps.editMode && nextProps.selectedProvider) {
            //provider associated with the mrr, if it exists
            selectedProvider = nextProps.selectedProvider;
        }

        let selectedSecondaryProvider;
        if (this.state.selectedSecondaryProvider) {
            //current / user-set value
            selectedSecondaryProvider = this.state.selectedSecondaryProvider;
        }
        else if (this.state.diagSaveObjDefaults.selectedSecondaryProvider) {
            //last added provider value
            selectedSecondaryProvider = { ...this.state.diagSaveObjDefaults.selectedSecondaryProvider };
        }
        
        this.setState({
            // if selected provider exists and user adds a new diag, pre-populate provider typeahead
            selectedProvider: selectedProvider,
            selectedSecondaryProvider: selectedSecondaryProvider,
            providerTypeID: this.state.providerTypeID ? this.state.providerTypeID : this.state.diagSaveObjDefaults.providerTypeID,
            dateOfServiceFrom: this.state.dateOfServiceFrom ? this.state.dateOfServiceFrom : this.state.diagSaveObjDefaults.dateOfServiceFrom,
            dateOfServiceTo: this.state.dateOfServiceTo ? this.state.dateOfServiceTo : this.state.diagSaveObjDefaults.dateOfServiceTo,
            medicalRecordTypeID: medicalRecordType,
            sourceID: this.state.sourceID ? this.state.sourceID : this.state.diagSaveObjDefaults.sourceID,
            rafTypeID: rafType,
            inputTouched: false,
            claimsProviderInputSet: false,
            initialSetupFinished: true
        }, () => {
            // handle initial imageID pre-population on modal open
            this.handleImagePrePopulation(); //todo: this might not be necessary with the focus handler in place
            this.initialDOSAndProviderTypeValidation();
            if (!this.isAutoClaimsLinkingDisabled()) {
                this.setClaimsProviderOptions(true);
            }
        });
    }

    // map diag comments to be used in multi-select
    mapDiagnosisComments = () => {
        let commentsDropdownValues = this.props.diagnosisComments ?
            this.props.diagnosisComments.map(x => {
                return { value: x.commentDefinitionID, label: x.commentText }
            }) : [];
        // if record type is not EMR, remove EMR Sig Imp comments from comment dropdown
        if ((this.props.editMode && this.props.editModalRow.medicalRecordTypeID !== MedicalRecordType.EMR) ||
            (!this.props.editMode && this.state.medicalRecordTypeID !== MedicalRecordType.EMR.toString())) {
            commentsDropdownValues = commentsDropdownValues.filter(x => this.commentIsNotSigImp(this.getCommentObjectViaCommentID(x)));
        }
        this.setState({
            commentsDropdownValues: commentsDropdownValues
        });
    }

    //todo: confirm that radvAuditEnrolleeHCCs are set prior to this being called
    //todo: should this use this.props.userID instead of row.user.userID?
    // populate form fields on edit
    populateEditFields = (row, selectedProvider, selectedSecondaryProvider) => {
        // set dos values to noon UTC to match the (internal) datepicker behavior
        const tempDOSFrom = new Date(row.dateOfServiceFrom);
        tempDOSFrom.setHours(12);
        const tempDOSTo = new Date(row.dateOfServiceTo);
        tempDOSTo.setHours(12);

        this.setState({
            providerTypeID: row.providerTypeID.toString(),
            dateOfServiceFrom: tempDOSFrom.toISOString(),
            dateOfServiceTo: tempDOSTo.toISOString(),
            diagDetails: [{
                diagnosisCD: row.diagnosisCD,
                description: row.description,
                hccPartCs: row.hccCsDisplay,
                hccPartDs: row.hccDsDisplay,
                claimNumber: row.initiatorIdentifier ? row.initiatorIdentifier : '',
                claimLinkID: row.claimLinkID ? row.claimLinkID : '',
                claimMatchType: row.claimMatchType ? row.claimMatchType : '',
                claimsRankingDetailID: row.claimsRankingDetailID ? row.claimsRankingDetailID : '',
                pages: row.pageNumFrom + '-' + row.pageNumTo === '0-0' ? '' : row.pageNumFrom + '-' + row.pageNumTo,
                pageNumFrom: row.pageNumFrom.toString() !== '0' ? row.pageNumFrom.toString() : '',
                pageNumTo: row.pageNumTo.toString() !== '0' ? row.pageNumTo.toString() : '',
            }],
            sourceID: row.sourceID.toString(),
            //medicalRecordDiagID : row.medicalRecordDiagID, is this actually needed?
            medicalRecordTypeID: row.medicalRecordTypeID.toString(),
            medicalRecordImageID: row.medicalRecordImageID ? row.medicalRecordImageID.toString() : '',
            diagnosisComments: row.diagnosisComments.map(x => { 
                return x.commentCD ? parseInt(x.commentCD, 10) : parseInt(x, 10) 
            }),
            evidenceImageID: row.evidenceImageID ? row.evidenceImageID.toString() : '',
            rafTypeID: row.rafTypeID.toString(),
            selectedProvider: selectedProvider,
            selectedSecondaryProvider: selectedSecondaryProvider,
            userID: row.user.userID,
            radvScore: row.radvScore ? row.radvScore : null,
            hccHierarchy: row.hccHierarchy ? row.hccHierarchy : null,
            radvAuditHCCIDs: row.radvAuditHCCIDs ? row.radvAuditHCCIDs.filter(x => {
                return x;
            }) : [],
            radvAuditHCCID12: row.radvAuditHCCIDs ? row.radvAuditHCCIDs.find(x => {
                return this.props.radvAuditEnrolleeHCCs.find(y => {
                    return y.radvAuditHCCID === x
                }).hccVersion === 'V12' 
            }) : '',
            radvAuditHCCID22: row.radvAuditHCCIDs ? row.radvAuditHCCIDs.find(x => {
                return this.props.radvAuditEnrolleeHCCs.find(y => {
                    return y.radvAuditHCCID === x
                }).hccVersion === 'V22' 
            }) : '',
            //},
            disableDOSThru: row.providerTypeID !== ProviderType.Inpatient
        })
    }

    //todo: confirm that this.props.imageRecords is available prior to this being called
    //todo: consider merging this with populateEditFields
    // populate form fields for additions sourced from the cdi alert panel "add diagnosis" action
    populateAlertAddDiagFields = (row, selectedProvider, selectedSecondaryProvider) => {
        //todo: do some of these other fields need to be set? need to test with radv, etc.
        this.setState({
            // description: this.state.description,
            // hccPartCs: this.state.hccPartCs,
            // hccPartDs: this.state.hccPartDs,
            //medicalRecordDiagID: '',
            // evidenceImageID: this.state.evidenceImageID,
            // pages: this.state.pages,
            // pageNumFrom: this.state.pageNumFrom,
            // pageNumTo: this.state.pageNumTo,
            // claimNumber: this.state.claimNumber,
            // claimLinkID: this.state.claimLinkID,
            // claimMatchType: this.state.claimMatchType,
            // claimsRankingDetailID: this.state.claimsRankingDetailID,
            //alert overrides - begin
            selectedProvider: selectedProvider,
            selectedSecondaryProvider: selectedSecondaryProvider,
            providerTypeID: row.providerTypeID.toString(),
            dateOfServiceFrom: (new Date(row.dateOfServiceFrom)).toISOString(),
            dateOfServiceTo: (new Date(row.dateOfServiceTo)).toISOString(),
            diagnosisCD: row.diagnosisCD,
            sourceID: row.sourceID.toString(),
            medicalRecordTypeID: row.medicalRecordTypeID.toString(),
            medicalRecordImageID: this.props.imageRecords.length > 0 ? this.props.imageRecords[0].id : -1,
            diagnosisComments: row.diagnosisComments.map(x => { return parseInt(x, 10) }),
            rafTypeID: row.rafTypeID.toString(),
            userID: this.props.userID,
            cdiAlertEntityDetailID: row.cdiAlertEntityDetailID,
            //alert overrides - end
            disableDOSThru: row.providerTypeID !== ProviderType.Inpatient
        });
    }

    handleModalToggle = (clearFormValues) => {
        this.props.handleModalToggle();

        if (this.state.showClaimsDiagGeneration) {
            this.setState({
                showClaimsDiagGeneration: false
            });
        }

        // on close of modal while editing or confirmation of a cancel action, clear the modal values
        if (this.props.editModalRow || clearFormValues) {
            //reset cdi alert panel "add diagnosis" selection
            this.props.setDiagFormData(null);

            //reset diag lookup selection
            this.props.selectDiagLookup('', '', false);

            this.setState({
                ...this.defaultDiagSaveObj,
                diagDetails: [{...this.defaultDiagDetailsSaveObj}],
                lastOpenedImage: null,
                validationErrorMessages: [],
                postValidationErrorMessages: [],
                inputTouched: false,
                initialSetupFinished: false
            });
        }
        else {
            this.setState({ initialSetupFinished: false });
        }
    }

    //returns the collection of state props formerly bundled into this.state.diagSaveObj
    getDiagSaveObj = (isAdd = false) => {
        const saveObj = {
            providerTypeID: this.state.providerTypeID,
            dateOfServiceFrom: this.state.dateOfServiceFrom,
            dateOfServiceTo: this.state.dateOfServiceTo,
            sourceID: this.state.sourceID,
            medicalRecordTypeID: this.state.medicalRecordTypeID,
            medicalRecordImageID: this.state.medicalRecordImageID,
            diagnosisComments: this.state.diagnosisComments,
            evidenceImageID: this.state.evidenceImageID,
            rafTypeID: this.state.rafTypeID,
            selectedProvider: this.state.selectedProvider,
            selectedSecondaryProvider: this.state.selectedSecondaryProvider,
            userID: this.props.userID,
        }
        if (isAdd) {
            saveObj["diagDetails"] = this.state.diagDetails.filter(d => d.diagnosisCD && d.diagnosisCD != '').map(diag => ({
                ...diag,
                initiatorTypeID: DiagnosisInitiatorType.UserEntered,
                initiatorIdentifier: diag.claimNumber ? diag.claimNumber : null
            }));
        }
        else{
            saveObj["diagnosisCD"] = this.state.diagDetails[0].diagnosisCD;
            saveObj["description"] = this.state.diagDetails[0].description;
            saveObj["hccPartCs"] = this.state.diagDetails[0].hccPartCs;
            saveObj["hccPartDs"] = this.state.diagDetails[0].hccPartDs;
            saveObj["claimNumber"] = this.state.diagDetails[0].claimNumber;
            saveObj["claimLinkID"] = this.state.diagDetails[0].claimLinkID;
            saveObj["claimMatchType"] = this.state.diagDetails[0].claimMatchType;
            saveObj["claimsRankingDetailID"] = this.state.diagDetails[0].claimsRankingDetailID;
            saveObj["pages"] = this.state.diagDetails[0].pages;
            saveObj["pageNumFrom"] = this.state.diagDetails[0].pageNumFrom;
            saveObj["pageNumTo"] = this.state.diagDetails[0].pageNumTo;
        }
        return saveObj;
    }

    toggleConfirmModal = () => {
        this.setState({ 
            showConfirmModal: !this.state.showConfirmModal,
        });
    }

    handleConfirm = () => {
        this.toggleConfirmModal();
        this.handleModalToggle(true);
    }

    onFieldBlur = (e, statePropertyName) => {
        let value = e && e.target ? e.target.value : e;
        let propertyName = statePropertyName;

        if (propertyName === "claimsProvider") {
            value = this.state.selectedProvider;
            propertyName = "providerMasterID";
        }

        this.setState({
            validationErrorMessages: [...this.state.validationErrorMessages.filter(x => {
                if (propertyName === 'dateOfServiceFrom' || propertyName === 'dateOfServiceTo') {
                    //handle combined DoS + claim number validations
                    return x.field !== 'dateOfServiceFrom' && x.field !== 'dateOfServiceTo' && x.field !== 'claimNumber';
                }
                else {
                    return x.field !== propertyName;
                }
            }), ...this.getValidation(propertyName, value)],
            inputTouched: true
        });
    }

    initialDOSAndProviderTypeValidation = () => {
        this.setState({
            validationErrorMessages: [...this.state.validationErrorMessages.filter(x => {
                    //handle combined DoS validations
                    return x.field !== 'dateOfServiceFrom' && x.field !== 'dateOfServiceTo';
            }), ...this.getValidation('dateOfServiceFrom', this.state.dateOfServiceFrom),
                ...this.getValidation('dateOfServiceTo', this.state.dateOfServiceTo)]
        }, () => {
            this.checkProviderType(this.state.providerTypeID);
        });
    }

    getValidation = (property, value) => {
        switch (property) {
            case 'providerMasterID': {
                const providerMasterID = this.state.selectedProvider ? this.state.selectedProvider.internalKey : null;
                return requiredFieldValidation(providerMasterID, 'invalidProviderID', 'providerMasterID', 'Provider ID');
            }
            case 'providerTypeID': {
                return requiredFieldValidation(value, 'invalidProviderType', 'providerTypeID', 'Provider Type');
            }
            case 'dateOfServiceFrom': {
                return dateOfServiceValidation('dateOfServiceFrom', 'DOS', value, 'DOS Thru',
                    this.state.dateOfServiceTo, !this.state.disableDOSThru, this.minDateOfService)
                    .concat(this.claimNumberValidation(this.state.claimNumber, value, this.state.dateOfServiceTo));
            }
            case 'dateOfServiceTo': {
                return dateOfServiceValidation('dateOfServiceTo', 'DOS', this.state.dateOfServiceFrom, 'DOS Thru',
                    value, !this.state.disableDOSThru, this.minDateOfService)
                    .concat(this.claimNumberValidation(this.state.claimNumber, this.state.dateOfServiceFrom, value));
            }
            case 'diagnosisCD': {
                return requiredFieldValidation(value, 'invalidDiagnosisCD', 'diagnosisCD', 'Diagnosis');
            }
            case 'sourceID': {
                return requiredFieldValidation(value, 'invalidSourceID', 'sourceID', 'Source');
            }
            case 'medicalRecordImageID': {
                return this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString() ?
                    requiredFieldValidation(value, 'invalidMedicalRecordImageID', 'medicalRecordImageID', 'Image ID')
                    : [];
            }
            case 'pages': {
                let errorArr = [];
                if (this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString()
                    || this.state.medicalRecordTypeID === MedicalRecordType.Onsite.toString())
                {
                    errorArr = requiredFieldValidation(value, 'pagesRequired', 'pages', 'Pages')
                        .concat(combinedPageNumValidations(value));
                }
                else {
                    if (value !== '') {
                        errorArr = combinedPageNumValidations(value);
                    }
                }
                return errorArr;
            }
            case 'medicalRecordTypeID': {
                return requiredFieldValidation(value, 'invalidMedicalRecordTypeID', 'medicalRecordTypeID',
                    'Medical Record Type');
            }
            case 'hccHierarchy': {
                return this.props.isRADV ?
                    requiredFieldObjectValidation(value, 'hccHierarchyID', 'missingHCCHierarchy', 'hccHierarchy',
                        'HCC Hierarchy')
                    : [];
            }
            case 'radvScore': {
                return this.props.isRADV ?
                    requiredFieldObjectValidation(value, 'radvScoreID', 'missingRADVScore', 'radvScore', 'RADV Score')
                    : [];
            }
            case 'claimNumber': {
                return this.claimNumberValidation(value);
            }
            default: {
                return [];
            }
        }
    }

    validateAllFields = () => {
        const errors = this.validationFields.reduce((acc, field) => {
            //aggregate validations for each field in this.validationFields
            return [...acc, ...this.getValidation(field, field == 'diagnosisCD' || field == 'pages' ? this.state.diagDetails[0][field] : this.state[field])];
        }, [...this.state.validationErrorMessages]).reduce((acc, error) => {
            //remove any duplicate errors (handles fields dependent on cross-field validations)
            if (!acc.find(x => x.id === error.id)) {
                acc.push(error);
            }
            return acc;
        }, []);

        this.setState({
            validationErrorMessages: errors
        }, () => {
            // call imageID blur after page error validations are made and saved to state
            this.imageIDBlur(this.state.medicalRecordImageID);
        });

        return errors && errors.length === 0;
    }

    toggleProviderSearchModal = () => {
        this.setState({
            showProviderModal: !this.state.showProviderModal,
            visible: this.state.showProviderModal
        });
    }

    toggleSecondaryProviderModal = () => {
        this.setState({
            showSecondaryProviderModal: !this.state.showSecondaryProviderModal,
            visible: this.state.showSecondaryProviderModal
        });
    }

    toggleEvidenceModal = () => {
        this.setState({
            showEvidenceModal: !this.state.showEvidenceModal,
            visible: this.state.showEvidenceModal
        }, () => {
            if (!this.state.showEvidenceModal)
            {
                this.props.reloadImages();
            }
        });
    }

    // handle provider typeahead input change -- set provider state value
    handleProviderTypeaheadChange = (e) => {
        this.setState({
            selectedProvider: e,
            claimLinkID: '',
            claimNumber: ''
        });
    }

    handleSecondaryProviderTypeaheadChange = (e) => {
        this.setState({
            selectedSecondaryProvider: e,
            claimLinkID: '',
            claimNumber: ''
        });
    }

    // handle select of provider from provider search modal -- set provider state value and update typeahead
    handleProviderSearchModalSelect = (internalKey, providerID, providerNPI, lastName, firstName, planProviderID) => {
        // when a selection is made from the ProviderNPISearchModal, the NPI is treated as the provider ID (aka planProviderID)
        if (internalKey) {
            this.setState({
                inputTouched: true,
                selectedProvider: {
                    internalKey: internalKey,
                    lastName: lastName,
                    firstName: firstName,
                    providerNPI: providerNPI,
                    planProviderID: planProviderID,
                    label: this.props.getProviderDropdownLabel(lastName, firstName, providerID, providerNPI, null)
                }
            }, () => {
                this.onFieldBlur('', 'providerMasterID');
            });
        }
        else {
            this.props.toast('error', 'Could not select provider.', 'Error');
        }
    }

    // handle select of secondary provider from secondary provider search modal --
    // set provider state value and update typeahead
    handleSecondaryProviderModalSelect = (internalKey, providerID, providerNPI, lastName, firstName, planProviderID) => {
        // when a selection is made from the ProviderNPISearchModal, the NPI is treated as the provider ID (aka planProviderID)
        if (internalKey) {
            this.setState({
                inputTouched: true,
                selectedSecondaryProvider: {
                    internalKey: internalKey,
                    lastName: lastName,
                    firstName: firstName,
                    providerNPI: providerNPI,
                    planProviderID: planProviderID,
                    label: this.props.getProviderDropdownLabel(lastName, firstName, providerID, providerNPI, null)
                }
            });
        }
        else {
            this.props.toast('error', 'Could not select provider.', 'Error');
        }
    }

    checkProviderType = (providerTypeID) => {
        // if provider type is Inpatient, disable DOSThru field
        const disableDOSThru = providerTypeID !== ProviderType.Inpatient.toString();
        this.setState({
            providerTypeID: providerTypeID,
            disableDOSThru: disableDOSThru
        }, () => {
            // if disableDOSThru, set dosTo to dosFrom value, then check difference between dates
            if (disableDOSThru) {
                this.setState({
                    dateOfServiceTo: this.state.dateOfServiceFrom,
                    // if dosTo is disabled, clear dosTo validation messages
                    validationErrorMessages: [...this.state.validationErrorMessages].filter(x => {
                        return x.field !== "dateOfServiceTo";
                    })
                }, this.checkDOSDifference);
            }
        });
    }

    handleProviderTypeChange = (e) => {
        if (e.target) {
            this.checkProviderType(e.target.value);
        }
    }

    handleDateChange = (statePropertyName) => (dateValue) => {
        //previously: dateValue = dateValue ? getTimeAsESTISODateString(new Date(dateValue)) : '';
        dateValue = dateValue ? dateValue : '';

        // perform dos validation
        if (statePropertyName === "dateOfServiceFrom") {
            this.onFieldBlur(dateValue, 'dateOfServiceFrom');
        }
        else if (statePropertyName === "dateOfServiceTo") {
            this.onFieldBlur(dateValue, 'dateOfServiceTo');
        }

        this.setState({ [statePropertyName]: dateValue }, () => {
            // if dateOfServiceFrom changes and dosTo is disabled, set dosTo to dosFrom, then checkDOSDifference()
            if (statePropertyName === "dateOfServiceFrom" && this.state.disableDOSThru) {
                this.setState({ dateOfServiceTo: dateValue }, () => {
                    this.checkDOSDifference();
                    if (!this.isAutoClaimsLinkingDisabled()) {
                        this.setClaimsProviderOptions();
                    }
                });
            }
            // else simply checkDOSDifference()
            else {
                this.checkDOSDifference();
                if (!this.isAutoClaimsLinkingDisabled()) {
                    this.setClaimsProviderOptions();
                }
            }
        });
    }

    clearDateField = (statePropertyName) => {
        this.setState({ [statePropertyName]: '' }, () => {
            this.onFieldBlur('', statePropertyName);
        });
    }

    // dos onFocus handler -- clears the dos state if a value is present
    dateFieldTouched = (statePropertyName) => {
        if (statePropertyName === "dateOfServiceFrom") {
            if (this.state.dateOfServiceFrom) {
                this.clearDateField(statePropertyName);
            }
        }
        else if (statePropertyName === "dateOfServiceTo") {
            if (this.state.dateOfServiceTo) {
                this.clearDateField(statePropertyName);
            }
        }
    }

    // check difference between dosFrom and dosTo and call diagnosisLookup to update HCCC, HCCD, and description
    checkDOSDifference = () => {
        let currentErrorMessages = [...this.state.validationErrorMessages].filter(x => {
            return x.field !== "dosTo";
        });

        const difference = getDifference(this.state.dateOfServiceFrom, this.state.dateOfServiceTo);
        if (difference && difference > this.props.maxDateDifference) {
            currentErrorMessages = [
                ...currentErrorMessages,
                { id: 'dosTo90Days', field: 'dosTo', errorText: "DOS Thru is more than " + this.props.maxDateDifference + " days from the DOS" }
            ];
        }

        this.setState({ validationErrorMessages: currentErrorMessages }, () => {          
            if (this.state.diagDetails[0].diagnosisCD) {
                this.diagnosisLookup(this.state.diagDetails[0].diagnosisCD, 0);
            }
        });
    } 

    // call on diagnosis input blur
    diagnosisLookup = (e, i) => {
        const diagnosisCD = e && e.target ? e.target.value : e;
        let currentErrorMessages = [...this.state.validationErrorMessages].filter(x => {
            return x.field !== "diagnosisCD";
        })

        axios.get(`/diagnosisLookup/`, {
            params: {
                memberInternalKey: this.props.memberID,
                diagnosisCD: this.state.diagDetails[i].diagnosisCD,
                dateOfServiceThru: formatISODateOnly(this.state.dateOfServiceTo ? new Date(this.state.dateOfServiceTo) : new Date())
            },
            cancelToken: this.source.token
        })
        .then((response) => {
            this.fillHCCInfo(response.data, i);
            currentErrorMessages = currentErrorMessages.concat(requiredFieldValidation(diagnosisCD, 'invalidDiagnosisCD', 'diagnosisCD', 'Diagnosis'));
            const diagStateUpdateDefaults = {
                inputTouched: true,
                diagLoaded: true,
                validationErrorMessages: currentErrorMessages
            };

            // logic for matchingRADVAuditHCCs
            if (this.props.isRADV && this.state.diagDetails[i].hccPartCs) {
                const hccPartCs = this.state.diagDetails[i].hccPartCs.split(',').map(x => {
                    return x.split('-');
                });
                const matchingRADVAuditHCCs = hccPartCs.map(hccPartC => {
                    const result = this.props.radvAuditEnrolleeHCCs ? this.props.radvAuditEnrolleeHCCs.find(hccObj => {
                        return hccObj.hccVersion === hccPartC[0] && hccObj.hccNumber === hccPartC[1]
                    }) : null;
                    return result ? result.radvAuditHCCID : null;
                }).filter(possiblyNull => {
                    return possiblyNull !== null
                });

                // if matching RADV AuditHCCs exist, set hccHierarchy to Full and radvAuditHCCIDs to matchingRADVAuditHCCs
                if (matchingRADVAuditHCCs.length > 0) {
                    this.setState({
                        hccHierarchy: { hccHierarchyID: 1, description: 'Full' },
                        radvAuditHCCIDs: matchingRADVAuditHCCs,
                        // set V12 dropdown value if matchingRADVAuditHCCs contains HCC from radvAuditEnrolleeHCCs
                        // which is also V12
                        radvAuditHCCID12: matchingRADVAuditHCCs.find(x => {
                            return this.props.radvAuditEnrolleeHCCs.find(y => {
                                return y.radvAuditHCCID === x
                            }).hccVersion === 'V12'
                        }),
                        // set V22 dropdown value if matchingRADVAuditHCCs contains HCC from radvAuditEnrolleeHCCs
                        // which is also V22
                        radvAuditHCCID22: matchingRADVAuditHCCs.find(x => {
                            return this.props.radvAuditEnrolleeHCCs.find(y => {
                                return y.radvAuditHCCID === x
                            }).hccVersion === 'V22'
                        }),
                        // },
                        ...diagStateUpdateDefaults
                    });
                }
                // else, set hccHierarchy to null and radvAuditHCCIDs empty
                else {
                    this.setState({
                        hccHierarchy: { description: null },
                        radvAuditHCCIDs: [],
                        radvAuditHCCID12: '',
                        radvAuditHCCID22: '',
                        ...diagStateUpdateDefaults
                    });
                }
            }
            else {
                this.setState({
                    ...diagStateUpdateDefaults
                });
            }
        })
        .catch((error) => {
            this.resetHccValuesAndDescription();
            this.setState({
                inputTouched: true,
                diagLoaded: true,
                validationErrorMessages: [
                    ...currentErrorMessages,
                    { id: 'diagnosisNotFound', field: 'diagnosisCD', errorText: error.response.data.message !== " " ? error.response.data.message : 'Diagnosis Code is invalid or incomplete' }
                ]
            });
        })
    }

    handleDiagnosisChange = (index, event) => {
        const { name, value } = event.target;
        this.setState((prevState) => {
            const rows = [...prevState.diagDetails];
            rows[index][name] = value.toUpperCase();
            return { rows };
        });
    }


    handleClaimNumberChange = (e,i) => {
        const value = e.target.value;
        this.setState(prevState => {
            const diags = [...prevState.diagDetails];
            diags[i]["claimNumber"] = value;
            return { diagDetails: diags};
        });
    }

    // called on claim number input blur. this validation is only applied for *manual* claims linking.
    claimNumberValidation = (e, dateOfServiceFrom = this.state.dateOfServiceFrom, dateOfServiceTo = this.state.dateOfServiceTo) => {
        const claimNumber = e && e.target ? e.target.value : e;
        const errorArr = [];

        if (!this.props.autoClaimsLinkingEnabled && claimNumber) {
            if (this.props.reduxDiags) {
                const claimsIDList = this.props.reduxDiags.find(x => x.type === "SET_CLAIMS_LIST");
                if (claimsIDList && claimsIDList.claimsList) {
                    const matchingClaims = claimsIDList.claimsList.filter(claim => claim.claimNumber === claimNumber);
                    if (matchingClaims.length > 0) {
                        //note: seems that all matches for a given claims number have the same dates, but this may not be guaranteed
                        const matchingClaim = matchingClaims[0];

                        const rangeStart = new Date(new Date(matchingClaim.dateOfServiceFrom).getTime() - (10 * 86400000));
                        const rangeEnd = new Date(new Date(matchingClaim.dateOfServiceThru).getTime() + (10 * 86400000));
                        if (!dateOfServiceFrom || !dateOfServiceTo) {
                            errorArr.push({
                                id: 'datesRequiredForClaimsLinking',
                                field: 'claimNumber',
                                errorText: this.state.disableDOSThru ? 'DoS is required for claims linking' : 'DoS From and DoS Thru are required for claims linking'
                            });
                        }
                        else {
                            if (new Date(dateOfServiceFrom) < rangeStart) {
                                errorArr.push({
                                    id: 'dosToBeforeClaimRange',
                                    field: 'claimNumber',
                                    errorText: `DoS From is more than 10 days before the claim's DoS From (${matchingClaim.dateOfServiceFrom})`
                                });
                            }
                            if (new Date(dateOfServiceTo) > rangeEnd) {
                                errorArr.push({
                                    id: 'dosToAfterClaimRange',
                                    field: 'claimNumber',
                                    errorText: `DoS Thru is more than 10 days after the claim's DoS Thru (${matchingClaim.dateOfServiceThru})`
                                });
                            }
                        }
                    }
                    else {
                        errorArr.push({
                            id: 'noMatchingClaim',
                            field: 'claimNumber',
                            errorText: `Could not find claim matching ${claimNumber}`
                        });
                    }
                }
            }
            else {
                this.props.toast('error', 'Could not validate claim number', 'Error');
                this.setState({ claimNumber: '' });
            }
        }

        return errorArr;
    }

    resetHccValuesAndDescription = () => {
        this.setState({
            description: '',
            hccPartCs: '',
            hccPartDs: ''
        });
    }

    // set state values of hccC, hccD, and description
    fillHCCInfo = (data, i) => {
        if (data) {
            if (data.hccPartCs) {
                data.hccCs = data.hccPartCs.map(x => x.model + "-" + x.value).toString();
            }
            if (data.hccPartDs) {
                data.hccDs = data.hccPartDs.map(x => x.model + "-" + x.value).toString();
            }
            this.setState((prevState) => {
                const rows = [...prevState.diagDetails];
                rows[i]['hccPartCs'] = data.hccCs;
                rows[i]['hccPartDs'] = data.hccDs;
                rows[i]['description'] = data.description;
                return { diagDetails: rows };
            });
        }
    }

    handleSourceTypeChange = (e) => {
        if (e.target) {
            this.setState({ sourceID: e.target.value });
        }
    }

    handleCommentsChange = (selectedComments) => {
        //get 'Signature Impaired' comment selection previous & current values
        const prevSigImpSelection = this.state.diagnosisComments.find(x => x === 201);
        const sigImpCommentSelection = selectedComments.find(x => x.value === 201);

        //get full comment objects for currently selected comments
        const selectedDiagComments = selectedComments.map(x => {
            return this.props.diagnosisComments.find(y => x.value === y.commentDefinitionID);
        });

        //get EMR Sig Imp comment selections
        const sigImpSelectedDiagComments = selectedDiagComments.filter(x => x.commentGroupID === CommentGroup.EMR);

        if (sigImpSelectedDiagComments.length > 0) {
            if (!sigImpCommentSelection) {
                if (prevSigImpSelection) {
                    //remove all EMR Sig Imp comments if 'Signature Impaired' is being deselected
                    selectedComments = selectedComments.filter(x => this.commentIsNotSigImp(this.getCommentObjectViaCommentID(x)));
                }
                else {
                    //add 'Signature Impaired' if it isn't selected
                    selectedComments = [...selectedComments, this.state.commentsDropdownValues.find(x => x.value === 201)];
                }
            }
        }

        this.setState({
            diagnosisComments: selectedComments.map(item => item.value),
            inputTouched: true
        });
    }

    commentIsNotSigImp = (comment) => {
        return comment.commentGroupID !== CommentGroup.EMR;
    }

    getCommentObjectViaCommentID = (comment) => {
        return this.props.diagnosisComments.find(x => x.commentDefinitionID === comment.value);
    }

    handleImageIDChange = (e) => {
        if (e.target) {
            this.setState({ medicalRecordImageID: e.target.value });
        }
    } 

    imageIDBlur = (e) => {
        const medicalRecordImageID = e && e.target ? e.target.value : e;
        if (this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString()) {
            this.setState({
                validationErrorMessages: [...this.state.validationErrorMessages].filter(x => {
                    return x.field !== "medicalRecordImageID";
                }).concat(
                    requiredFieldValidation(medicalRecordImageID, 'invalidMedicalRecordImageID', 'medicalRecordImageID', 'Image ID')
                ),
                inputTouched: true
            });
        }
        else {
            this.setState({
                validationErrorMessages: [...this.state.validationErrorMessages].filter(x => {
                    return x.field !== "medicalRecordImageID";
                }),
                inputTouched: true
            });
        }
    }

    handleImagePrePopulation = () => {
        //retrieve last opened image to populate image dropdown if modal is visible and window has focus
        if (this.state.visible) {
            axios.get(`/medicalrecordreview/${this.props.medicalRecordReviewID}/images/lastopened`, {
                cancelToken: this.source.token
            })
            .then((response) => {
                this.setState({ lastOpenedImage: response.data }, () => {
                    // populate imageID if adding a diag, medicalRecordType is SCAN, and last opened image exists
                    if (!this.props.editMode && this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString() 
                        && this.state.lastOpenedImage
                        && this.props.imageRecords.some(x => x.id === this.state.lastOpenedImage.medicalRecordImageID)) {

                        this.setState({ medicalRecordImageID: this.state.lastOpenedImage.medicalRecordImageID })
                    }
                });
            })
            .catch((error) => {
                if (error.response && error.response.status && error.response.status !== 404) {
                    this.props.toast('error', 'Could not load last opened image.', 'Error');
                }
            });
        }
    }

    handleEvidenceIDChange = (e) => {
        if (e.target) {
            this.setState({
                evidenceImageID: e.target.value,
                inputTouched: true
            });
        }
    }

    handlePageChange = (e, i) => {
        if (e.target) {
            const value = e.target.value;
            let pageFrom = value;
            let pageTo = value;
            if (value.indexOf('-') !== -1) {
                const splitPages = value.split('-');
                pageFrom = splitPages[0];
                pageTo = splitPages[1];
            }
            this.setState(prevState => {
                const diags = [...prevState.diagDetails];
                diags[i]["pages"] = value;
                diags[i]["pageNumFrom"] = pageFrom;
                diags[i]["pageNumTo"] = pageTo;
                return { diagDetails: diags};
            });
        }
    }

    handleMedicalRecordTypeChange = (e) => {
        if (e.target) {
            let newSelectedDiagComments = [...this.state.diagnosisComments];
            if (e.target.value !== MedicalRecordType.EMR.toString()) {
                // if record type is not EMR, remove Sig Imp comments from dropdown values
                const dropdownValues = this.state.commentsDropdownValues.filter(x => this.commentIsNotSigImp(this.getCommentObjectViaCommentID(x)));
                newSelectedDiagComments = this.state.diagnosisComments && 
                    this.state.diagnosisComments.filter(x => {
                        return this.props.diagnosisComments.find(y => { return x === y.commentDefinitionID })
                            .commentGroupID !== CommentGroup.EMR;
                    }); 
                this.setState({
                    commentsDropdownValues: dropdownValues,
                    // remove selected Sig Imp comments from dropdown list
                    diagnosisComments: newSelectedDiagComments
                });
            }

            if (e.target.value === MedicalRecordType.Scan.toString()) { // if record type is SCAN
                this.setState({ 
                    diagnosisComments: newSelectedDiagComments,
                    medicalRecordTypeID: e.target.value
                }, () => {
                    this.handleImagePrePopulation();
                })
            }
            else {
                this.setState({
                    medicalRecordTypeID: e.target.value,
                    medicalRecordImageID: '',
                    diagnosisComments: newSelectedDiagComments,
                    pages: '',
                    pageNumFrom: '',
                    pageNumTo: ''
                }, () => {
                    // if record type is EMR, call mapDiagnosisComments to keep Sig Imp EMR comments in comments dropdown
                    if (this.state.medicalRecordTypeID === MedicalRecordType.EMR.toString()) {
                        this.mapDiagnosisComments();
                    }
                    // remove unnecessary error messages
                    const currentErrorMessages = [...this.state.validationErrorMessages].filter(x => {
                            return x.field !== "pages" && x.field !== "medicalRecordImageID";
                    });

                    // call pages validation again
                    this.setState({
                        validationErrorMessages: currentErrorMessages
                    }, () => this.onFieldBlur(this.state.pages, 'pages'));
                })
            }
        }
    }

    handleRafTypeChange = (e) => {
        if (e.target) {
            this.setState({
                rafTypeID: e.target.value,
                inputTouched: true
            });
        }
    }

    toggleRADVModal = () => {
        this.setState({
            showRADVModal: !this.state.showRADVModal
        })
    }

    setRADVScore = (radvScore) => {
        this.onFieldBlur(radvScore, 'radvScore');
        this.setState({
            radvScore: radvScore,
            inputTouched: true
        })
    }

    generateRADVAuditHCCIDs = () => {
        const radvAuditHCCIDs = [];
        if (this.state.radvAuditHCCID12) {
            radvAuditHCCIDs.push(this.state.radvAuditHCCID12);
        }
        if (this.state.radvAuditHCCID22) {
            radvAuditHCCIDs.push(this.state.radvAuditHCCID22);
        }
        return radvAuditHCCIDs;
    }

    setHCCHierarchy = (hccHierarchy) => {
        this.onFieldBlur(hccHierarchy, 'hccHierarchy');
        this.setState({
            hccHierarchy: hccHierarchy,
            radvAuditHCCIDs: hccHierarchy.description !== "Non-Related" ? this.generateRADVAuditHCCIDs() : [],
            inputTouched: true
        })
    }

    handleRADVAuditHCCChange = (e, version) => {
        if (e.target) {
            this.setState({
                radvAuditHCCID12: version === 'V12' ? parseInt(e.target.value, 10) : this.state.radvAuditHCCID12,
                radvAuditHCCID22: version === 'V22' ? parseInt(e.target.value, 10) : this.state.radvAuditHCCID22
            }, () => {
                this.setState({
                    radvAuditHCCIDs: this.generateRADVAuditHCCIDs(),
                    inputTouched: true
                })
            })
        }
    }

    addDiag = () => {
        const isValid = this.state.validationErrorMessages.length === 0 && this.validateAllFields();
        if (isValid) {
            if (!this.isAutoClaimsLinkingDisabled()) {
                this.getProviderGroupsAndClaimLink(true)
                .then(() => {
                    Promise.resolve(this.getAddDiagPromise());
                })
                .catch(() => {
                    Promise.resolve(this.getAddDiagPromise());
                });
            }
            else {
                Promise.resolve(this.getAddDiagPromise());
            }
        }
    }

    getAddDiagPromise = () => {
        if(this.state.diagDetails.some(d=>d.diagnosisCD == '' || d.pages == '')){
            const currentErrorMessages = [];
            currentErrorMessages.push({ id: 'diag', field: 'diag', errorText: 'Diagnosis and Pages required!' });
            this.setState({
                postValidationErrorMessages: currentErrorMessages
            });
        }
        else{
            return axios.post(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiags`,
            this.prepareDiagAddModel())
        .then((response) => {
            // add record to alert entity detail/diag xref table for the cdi alert panel "add diagnosis" flow
            if (this.state.cdiAlertEntityDetailID) {
                response.data.forEach(hcc => {
                    axios.post(`/medicalRecordReview/${this.props.medicalRecordReviewID}/CDIAlertEntityDetailDiag`, {
                        CDIAlertEntityDetailID: this.state.cdiAlertEntityDetailID,
                        MedicalRecordDiagID: hcc.medicalRecordDiagID,
                        cancelToken: this.source.token
                    })
                    .then((response) => {
                        this.setState({
                            claimsDiagGenerationCount: response.data.claimsDiagGenerationCount ? response.data.claimsDiagGenerationCount : 0
                        }, () => this.diagAddOrUpdateOnSuccess('add'));
                    })
                    .catch((error) => {
                        this.props.toast('error', 'Diagnosis was not fully saved (alert entity detail record was not created).', 'Error');
                    });
                });
            }
            else {
                this.setState({
                    claimsDiagGenerationCount: response.data.claimsDiagGenerationCount ? response.data.claimsDiagGenerationCount : 0
                }, () => this.diagAddOrUpdateOnSuccess('add'));
            }
        })
        .catch((error) => {
            this.errorCallbackTasksForMultipleDiags(error);
        });
        }
    }

    prepareDiagAddModel = () => {
        return {
            ...this.getDiagSaveObj(true),
            //begin add overrides
            providerMasterID: this.state.selectedProvider ? this.state.selectedProvider.internalKey : null,
            secondaryProviderMasterID: this.state.selectedSecondaryProvider ? this.state.selectedSecondaryProvider.internalKey : null,
            planProviderID: this.state.selectedProvider ? this.state.selectedProvider.planProviderID : null,
            secondaryPlanProviderID: this.state.selectedSecondaryProvider ? this.state.selectedSecondaryProvider.planProviderID : null,
            //initiatorTypeID: DiagnosisInitiatorType.UserEntered,
            //initiatorIdentifier: this.state.claimNumber ? this.state.claimNumber : null,
            diagnosisCDReviewStatusID: this.props.isCoderQA ? 11 : 0,
            //userID: this.props.userID,
            //end add overrides
            cancelToken: this.source.token
        };
    }

    diagAddOrUpdateOnSuccess = (action) => {
        this.props.getDiagnosisData();
        this.props.getDiagnosisHistoryData();

        if (!this.isParallelClaimsRenderEnabled()) {
            this.handleModalToggle();
        }
        
        // maintain form values for providerID, providerType, DoS, medicalRecordType, source, and rafType info.
        // these defaults are updated after each successful diag add.
        if (action === 'add') {
            this.setState({
                ...this.defaultDiagSaveObj,
                diagDetails: [{...this.defaultDiagDetailsSaveObj}],
                selectedProvider: this.state.selectedProvider,
                selectedSecondaryProvider: this.state.selectedSecondaryProvider,
                providerTypeID: this.state.providerTypeID,
                dateOfServiceFrom: this.state.dateOfServiceFrom,
                dateOfServiceTo: this.state.dateOfServiceTo,
                medicalRecordTypeID: this.state.medicalRecordTypeID,
                sourceID: this.state.sourceID,
                rafTypeID: this.state.rafTypeID,
                diagSaveObjDefaults: {
                    selectedProvider: this.state.selectedProvider,
                    selectedSecondaryProvider: this.state.selectedSecondaryProvider,
                    providerTypeID: this.state.providerTypeID,
                    dateOfServiceFrom: this.state.dateOfServiceFrom,
                    dateOfServiceTo: this.state.dateOfServiceTo,
                    medicalRecordTypeID: this.state.medicalRecordTypeID,
                    sourceID: this.state.sourceID,
                    rafTypeID: this.state.rafTypeID
                },
                postValidationErrorMessages: [],
                showClaimsDiagGeneration: this.isParallelClaimsRenderEnabled()
            });
        }
        else {
            this.setState({
                ...this.defaultDiagSaveObj,
                diagDetails: [{...this.defaultDiagDetailsSaveObj}],
                postValidationErrorMessages: [],
                showClaimsDiagGeneration: this.isParallelClaimsRenderEnabled()
            });
        }

        //reset diag lookup selection
        this.props.selectDiagLookup('', '', false);

        if (this.props.isCoderQA) {
            //this.props.toggleDiagFeedbackModal(currentDiag);
            this.props.toggleDiagFeedbackModal(this.getDiagSaveObj());
        }
    }

    updateDiag = (reviewStatusID, isUpdateAndApprove) => {
        const isValid = this.state.validationErrorMessages.length === 0 && this.validateAllFields(); 
        if (isValid) {
            if (!this.isAutoClaimsLinkingDisabled()) {
                this.getProviderGroupsAndClaimLink(true)
                .then(() => {
                    Promise.resolve(this.getUpdateDiagPromise(reviewStatusID, isUpdateAndApprove));
                })
                .catch(() => {
                    Promise.resolve(this.getUpdateDiagPromise(reviewStatusID, isUpdateAndApprove));
                });
            }
            else {
                Promise.resolve(this.getUpdateDiagPromise(reviewStatusID, isUpdateAndApprove));
            }
        }
    }

    getUpdateDiagPromise = (reviewStatusID, isUpdateAndApprove) => {
        return axios.put(`/medicalRecordReview/${this.props.medicalRecordReviewID}/medicalRecordDiag/${this.props.editModalRow.medicalRecordDiagID}`,
            this.prepareDiagEditModel(reviewStatusID, isUpdateAndApprove))
        .then((response) => {
            this.setState({
                claimsDiagGenerationCount: response.data.claimsDiagGenerationCount ? response.data.claimsDiagGenerationCount : 0
            });
            this.diagAddOrUpdateOnSuccess('edit');
        })
        .catch((error) => {
            this.errorCallbackTasks(error);
        });
    }

    prepareDiagEditModel = (reviewStatusID, isUpdateAndApprove) => {
        const diagSaveObj = this.getDiagSaveObj();
        let initiatorIdentifier = this.props.editModalRow.initiatorIdentifier;
        if (this.props.editModalRow.initiatorTypeID === DiagnosisInitiatorType.UserEntered) {
            initiatorIdentifier = this.state.diagDetails[0].claimNumber ? this.state.diagDetails[0].claimNumber : null;
        }
        return {
            ...diagSaveObj,
            providerMasterID: diagSaveObj.selectedProvider ? diagSaveObj.selectedProvider.internalKey : null,
            secondaryProviderMasterID: diagSaveObj.selectedSecondaryProvider ? diagSaveObj.selectedSecondaryProvider.internalKey : null,
            planProviderID: diagSaveObj.selectedProvider ? diagSaveObj.selectedProvider.planProviderID : null,
            secondaryPlanProviderID: diagSaveObj.selectedSecondaryProvider ? diagSaveObj.selectedSecondaryProvider.planProviderID : null,
            medicalRecordDiagID: this.props.editModalRow.medicalRecordDiagID,
            initiatorTypeID: this.props.editModalRow.initiatorTypeID,
            initiatorIdentifier: initiatorIdentifier,
            updateAndApprove: isUpdateAndApprove ? isUpdateAndApprove : false, // if true, updates and approves diag
            diagnosisCDReviewStatusID: reviewStatusID ? reviewStatusID: 10, // review status ID is to identify if approve/reject/reopen
            expectedVersion: this.props.editModalRow.updatedDateTime,
            //userID: this.props.userID
        };
    }

    //todo: rewrite this (no nested forEach)
    errorCallbackTasks = (error) => {
        //todo: replace this w/ a function chain
        const currentErrorMessages = [];
        if (error.response && error.response.data && error.response.data.modelState) {
            Object.keys(error.response.data.modelState).forEach(function (key, index, value) {
                error.response.data.modelState[key].forEach(x => {
                    currentErrorMessages.push({ id: key, field: key, errorText: x });
                });
            });
        }
        else if (error.response && error.response.data && error.response.data.message) {
            currentErrorMessages.push({ id: 'serverError', field: 'unknown', errorText: error.response.data.message ? error.response.data.message : "An error has occurred" });
        }
        else {
            currentErrorMessages.push({ id: 'serverErrorCatchAll', field: 'unknown', errorText: "An error has occurred" });
        }
        this.setState({
            postValidationErrorMessages: currentErrorMessages
        });
    }

    errorCallbackTasksForMultipleDiags = (error) => {
        //todo: replace this w/ a function chain
        const currentErrorMessages = [];
        if (error.response && error.response.data && error.response.data.modelState) {
            Object.keys(error.response.data.modelState).forEach(function (key, index, value) {
                if(key.toLowerCase().includes('diagnosiscd')){
                    if(key.toLowerCase().includes('diagnosiscd:')){
                        error.response.data.modelState[key].forEach(x => {
                            const messages = x.split(';');
                            messages.forEach((message, index )=> {
                                currentErrorMessages.push({ id: key+'-'+index, field: key+'-'+index, errorText: message });
                            });
                        });
                    }
                }
                else{
                    error.response.data.modelState[key].forEach(x => {
                        currentErrorMessages.push({ id: key, field: key, errorText: x });
                    });
                }
            });
        }
        else if (error.response && error.response.data && error.response.data.message) {
            currentErrorMessages.push({ id: 'serverError', field: 'unknown', errorText: error.response.data.message ? error.response.data.message : "An error has occurred" });
        }
        else {
            currentErrorMessages.push({ id: 'serverErrorCatchAll', field: 'unknown', errorText: "An error has occurred" });
        }
        this.setState({
            postValidationErrorMessages: currentErrorMessages
        });
    }

    handleClaimsProviderChange = (e) => {
        this.setState({
                selectedProvider: e,
                claimLinkID: '',
                claimNumber: ''
            // }
        }, this.setState({ claimsProviderInputSet: Boolean(e) }) );
    }

    // loads claim provider options after obtaining a member's claims for the selected DOS range
    setClaimsProviderOptions = (isInitialSetup = false) => {
        if (!isInitialSetup) {
            this.setState({
                claimLinkID: '',
                claimNumber: ''
            });
        }

        if (this.state.dateOfServiceFrom) {
            const claimDOSRange = this.getPaddedDOSRange(this.claimRangePadding);
            const dosKey = `${claimDOSRange.dosFrom},${claimDOSRange.dosTo}`;

            const mapping = this.props.claimsProviderMap.get(dosKey);
            if (mapping) {
                //load client-side data from map (prop) if available
                this.setClaimsProviderState(mapping, isInitialSetup);
            }
            else {
                //get claims providers from api
                axios.get('/claimprovider', {
                    params: {
                        dateOfServiceFrom: claimDOSRange.dosFrom,
                        dateOfServiceTo: claimDOSRange.dosTo,
                        memberMasterID: this.props.memberID
                    },
                    cancelToken: this.source.token
                })
                .then((claimResponse) => {
                    //store claims providers for later use to prevent repeating (expensive) api requests
                    this.props.setClaimsAndClaimProviders(dosKey, claimResponse.data);
                    this.setClaimsProviderState(claimResponse.data.claimProviders, isInitialSetup);
                })
                .catch((error) => {
                    this.setState({ claimsProviders: [] });
                    this.props.toast('error', 'Could not load claim provider suggestions.', 'Error');
                });
            }
        }
        else {
            this.setState({ claimsProviders: [] });
        }
    }

    //todo: find a better name for claimRangePadding and move it to a configurable
    // pads the selected DOS range by a config-supplied number of days.
    // time values are set to noon UTC to match the (internal) datepicker behavior.
    // note: ignoring daylight savings, etc. until a proper date lib is put into place
    getPaddedDOSRange = (claimRangePadding = 0, dosFrom = null, dosTo = null) => {
        const dosRange = {
            dosFrom: null,
            dosTo: null
        };
        const dateOffset = 24 * 3600000 * claimRangePadding;

        if (!dosFrom && this.state.dateOfServiceFrom) {
            const tempDOSFrom = new Date(this.state.dateOfServiceFrom);
            tempDOSFrom.setHours(12);
            tempDOSFrom.setTime(tempDOSFrom.getTime() - dateOffset);
            dosRange.dosFrom = tempDOSFrom.toISOString();
        }

        if (!dosTo) {
            let tempDOSTo = this.state.dateOfServiceTo ?
                new Date(this.state.dateOfServiceTo)
                : new Date(this.state.dateOfServiceFrom);
            tempDOSTo.setHours(12);
            tempDOSTo.setTime(tempDOSTo.getTime() + dateOffset);
            dosRange.dosTo = tempDOSTo.toISOString();
        }

        return dosRange;
    }

    //todo: group + order claims provider suggestions by frequency(?)
    // set claims providers via the "providers" param.
    // if isInitialSetup is true and a claim number is present (i.e. editing a claim diag),
    // attempts to load the claim link.
    setClaimsProviderState = (providers, isInitialSetup) => {
        if (!isInitialSetup) {
            this.setState({
                selectedProvider: '',
                claimsProviders: providers.length > 0 ? providers.map(x => {
                    return {
                        ...x,
                        internalKey: x.providerMasterID,
                        label: this.props.getProviderDropdownLabel(x.lastName, x.firstName,
                            x.providerID, x.providerNPI, null)
                    };
                }) : []
            });
        }
        else {
            this.setState({
                claimsProviders: providers.length > 0 ? providers.map(x => {
                    return {
                        ...x,
                        internalKey: x.providerMasterID,
                        label: this.props.getProviderDropdownLabel(x.lastName, x.firstName,
                            x.providerID, x.providerNPI, null)
                    };
                }) : []
            }, () => {
                if (this.state.claimNumber) {
                    this.getProviderGroupsAndClaimLink();
                }
            });
        }
    };

    //todo: combine provider grouping caching for "case: new provider, new secondary provider"
    // obtains the provider grouping(s) for the provider selection(s), either from the cache prop or via api request(s).
    // on success, builds a claim link promise, which is either returned (for the diag add/edit flow)
    // or resolved immediately.
    getProviderGroupsAndClaimLink = (isDiagSave = false) => {
        const selectedProvider = this.state.selectedProvider;
        const selectedSecondaryProvider = this.state.selectedSecondaryProvider;
        this.setState({ loadingClaimLink: true });

        return new Promise((resolve, reject) => {
            if (selectedProvider && selectedProvider.planProviderID && this.state.dateOfServiceFrom) {
                const claimDOSRange = this.getPaddedDOSRange(this.claimRangePadding);
                const dosKey = `${claimDOSRange.dosFrom},${claimDOSRange.dosTo}`;
                const claims = this.props.claimsMap.get(dosKey);

                if (claims && claims.length > 0) {
                    const dosRange = this.getPaddedDOSRange();
                    const groupingPromises = [];

                    //check cache (providerGroupingMap) for provider's grouping
                    const cachedProviderGroup = this.props.providerGroupMap.get(selectedProvider.planProviderID);
                    if (!cachedProviderGroup) {
                        groupingPromises.push(this.getProviderGroupPromise(selectedProvider.planProviderID));
                    }

                    //if a secondary provider has been selected, check cache (providerGroupingMap) for secondary provider's grouping
                    let cachedSecondaryProviderGroup = null;
                    if (selectedSecondaryProvider) {
                        if (selectedSecondaryProvider.planProviderID) {
                            cachedSecondaryProviderGroup = this.props.providerGroupMap.get(selectedSecondaryProvider.planProviderID);
                            if (!cachedSecondaryProviderGroup) {
                                groupingPromises.push(this.getProviderGroupPromise(selectedSecondaryProvider.planProviderID));
                            }
                        }
                        else {
                            //this.props.toast('error', 'Could not load claim linking data for the selected secondary provider.', 'Error');
                            setTimeout(() => this.setState({ loadingClaimLink: false }), 700);
                            resolve();
                        }
                    }

                    if (groupingPromises.length > 0) {
                        //execute /providergrouping requests, cache new grouping data, then find claim link
                        Promise.all(groupingPromises)
                        .then((responses) => {
                            if (selectedSecondaryProvider && selectedSecondaryProvider.planProviderID) {
                                if (!cachedProviderGroup && !cachedSecondaryProviderGroup) {
                                    //case: new provider, new secondary provider
                                    //cache provider group responses in parent
                                    this.props.setProviderGroup(selectedProvider.planProviderID, responses[0].data);
                                    this.props.setProviderGroup(selectedSecondaryProvider.planProviderID, responses[1].data);

                                    resolve(this.getRankedClaimLink(claims, responses[0].data, responses[1].data,
                                        dosRange.dosFrom, dosRange.dosTo, isDiagSave));
                                }
                                else if (!cachedSecondaryProviderGroup) {
                                    //case: cached provider, new secondary provider
                                    this.props.setProviderGroup(selectedSecondaryProvider.planProviderID, responses[0].data);
                                    resolve(this.getRankedClaimLink(claims, cachedProviderGroup, responses[0].data,
                                        dosRange.dosFrom, dosRange.dosTo, isDiagSave));
                                }
                                else {
                                    //case: new provider, cached secondary provider
                                    this.props.setProviderGroup(selectedProvider.planProviderID, responses[0].data);
                                    resolve(this.getRankedClaimLink(claims, responses[0].data, cachedSecondaryProviderGroup,
                                        dosRange.dosFrom, dosRange.dosTo, isDiagSave));
                                }
                            }
                            else {
                                //case: new provider, no secondary provider
                                this.props.setProviderGroup(selectedProvider.planProviderID, responses[0].data);
                                resolve(this.getRankedClaimLink(claims, responses[0].data, null,
                                    dosRange.dosFrom, dosRange.dosTo, isDiagSave));
                            }
                        })
                        .catch((error) => {
                            this.props.toast('error', 'Could not load claim linking data.', 'Error');
                            this.setState({ loadingClaimLink: false });
                            resolve();
                        });
                    }
                    else {
                        //find claim link via cached provider grouping data
                        if (selectedSecondaryProvider && selectedSecondaryProvider.planProviderID) {
                            //case: cached provider, cached secondary provider
                            resolve(this.getRankedClaimLink(claims, cachedProviderGroup, cachedSecondaryProviderGroup,
                                dosRange.dosFrom, dosRange.dosTo, isDiagSave));
                        }
                        else {
                            //case: cached provider, no secondary provider
                            resolve(this.getRankedClaimLink(claims, cachedProviderGroup, null,
                                dosRange.dosFrom, dosRange.dosTo, isDiagSave));
                        }
                    }
                }
                else {
                    //this.props.toast('warning', 'Claim linking is unavailable for the current selections.', '');
                    setTimeout(() => this.setState({ loadingClaimLink: false }), 700);
                    resolve();
                }
            }
            else {
                //this.props.toast('warning', 'Claim linking is unavailable for the current selections.', '');
                setTimeout(() => this.setState({ loadingClaimLink: false }), 700);
                resolve();
            }
        });
    }

    getProviderGroupPromise = (planProviderID) => {
        return axios.get('/providergroup', {
            params: {
                planProviderID
            },
            cancelToken: this.source.token
        });
    }

    //todo: cache claim link by dosKey+planProviderID (composite)(?)
    // determines if a rank 1-7 claim is available (via the claim and providergroup data).
    // if so, the service will generate ClaimLink (if needed) and MedicalRecordDiagClaimLink records.
    getRankedClaimLink = (claims, providerGroup, secondaryProviderGroup, dosFrom, dosTo, isDiagSave = false) => {
        const claimPromise = this.getRankedClaimLinkPromise(claims, providerGroup, secondaryProviderGroup, dosFrom, dosTo);
        if (isDiagSave) {
            // chained diag add/update flow (i.e. return claimPromise for resolution, then add/update diag, etc.)
            return claimPromise;
        }
        else {
            // "immediate" claim link button onClick flow (claimPromise is resolved right away)
            Promise.resolve(claimPromise);
        }
    }

    getRankedClaimLinkPromise = (claims, providerGroup, secondaryProviderGroup, dosFrom, dosTo) => {
        return axios.post('/claimlink', {
            claims,
            providerGroup,
            secondaryProviderGroup,
            dateOfServiceFrom: dosFrom,
            dateOfServiceTo: dosTo,
            cancelToken: this.source.token
        })
        .then((response) => {
            if (response.data && response.data.claimLinkID && response.data.externalClaimID) {
                //this.props.toast('success', 'Claim link found.', '');
                this.setState({
                    claimLinkID: response.data.claimLinkID,
                    claimMatchType: response.data.claimMatchType,
                    claimNumber: response.data.externalClaimID,
                    claimsRankingDetailID: response.data.claimsRankingDetailID,
                    loadingClaimLink: false
                });
            }
            else {
                this.clearClaimLink()
                //this.props.toast('warning', 'Claim link unavailable.', '');
                this.setState({
                    loadingClaimLink: false
                });
            }
        })
        .catch((error) => {
            this.clearClaimLink()
            this.props.toast('error', 'Could not perform claim linking.', 'Error');
            this.setState({
                loadingClaimLink: false
            });
        });
    }

    clearClaimLink = () => {
        if (this.state.claimLinkID || this.state.claimNumber) {
            this.setState({
                claimLinkID: '',
                claimMatchType: '',
                claimNumber: '',
                claimsRankingDetailID: ''
            });
        }
    }

    isProviderTypeaheadDisabled = () => {
        return this.state.claimsProviderInputSet ||
            DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(this.props.editModalRow);
    }

    // used with claims provider, secondary provider, claim link button, edit population, and diag add/edit claim linking
    isAutoClaimsLinkingDisabled = () => {
        return this.lineOfBusiness !== 'Commercial' || !this.props.autoClaimsLinkingEnabled ||
            DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(this.props.editModalRow);
    }

    // claimsProviderInputSet disables either the claims provider or all provider input
    // (both cannot be enabled simultaneously)
    isClaimsProviderDisabled = () => {
        return this.isAutoClaimsLinkingDisabled() ||
            (!this.state.claimsProviderInputSet && Boolean(this.state.selectedProvider));
    }

    // determines if the parallel claims review post-add/update display is available
    isParallelClaimsRenderEnabled = () => {
        return this.props.parallelClaimsDiagCaptureEnabled && !this.isAutoClaimsLinkingDisabled();
    }

    handleAddRow = () => {
        this.setState((prevState) => ({
            diagDetails: [...prevState.diagDetails, { diagnosisCD: '', hccPartCs: '', hccPartDs: '', description: '', claimNumber: '', claimLinkID: '', claimMatchType: '', claimsRankingDetailID: '', pages: '' }]
        }));
    }
 
    handleRemoveRow = (index) => {
        this.setState((prevState) => {
            const rows = [...prevState.diagDetails];
            rows.splice(index, 1);
            return { diagDetails: rows };
        });
    }

    isEmpty = (value) => {
        return !value || value.trim() === '';
    }

    renderDiagRows = (isClaimsOrRiskMitigationDiagnosis) => {
        const borderStyle = !this.state.isOpenForEdit
            ? { borderBottom: '1px dotted #ccc', width: '98.5%', margin: '0 auto', marginBottom: '5px' }
            : {};
        return (
            <div>
                {this.state.diagDetails.map((row, index) => (
                    <>
                    <Row key={`diagDetails-first-`+index}>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Diagnosis</ControlLabel>
                                <FormControl
                                    type="text"
                                    className="uppercaseText"
                                    name="diagnosisCD"
                                    value={row.diagnosisCD}
                                    onChange={(e) => this.handleDiagnosisChange(index, e)}
                                    onBlur={(e) => this.diagnosisLookup(e, index)}
                                    disabled={isClaimsOrRiskMitigationDiagnosis || this.props.editQABool} 
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>HCC-C</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="hccPartCs"
                                    value={row.hccPartCs}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>HCC-D</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="hccPartDs"
                                    value={row.hccPartDs}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem' }}>Description</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="description"
                                    value={row.description}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {!this.state.isOpenForEdit && 
                            <Row key={`diagDetails-second-` + index}>
                                <Col xs={6}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }}>Claim Number</ControlLabel>
                                        <Row>
                                            <Col xs={10} style={{ paddingRight: '0.2rem' }}>
                                                <FormControl type="text" className="uppercaseText" value={row.claimNumber}
                                                    onChange={(e) => this.handleClaimNumberChange(e,index)} onBlur={(e) => this.onFieldBlur(e, 'claimNumber')}
                                                    disabled={isClaimsOrRiskMitigationDiagnosis || !this.isAutoClaimsLinkingDisabled()} />
                                            </Col>
                                            <Col xs={2} style={{ paddingRight: '0rem'}}>
                                                {this.renderClaimLinkButton()}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem' }} className={this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString()
                                            || this.state.medicalRecordTypeID === MedicalRecordType.Onsite.toString() ? "requiredField" : ""}>Pages</ControlLabel>
                                        <FormControl type="text" value={row.pages} onChange={(e) => this.handlePageChange(e, index)}
                                            onBlur={(e) => this.onFieldBlur(e, 'pages')} />
                                    </FormGroup>
                                </Col>
                                <Col xs={1}>
                                    <Button style={{ marginTop: '2.5rem' }} className="deleteButton" onClick={() => this.handleRemoveRow(index)} disabled={this.state.diagDetails.length <= 1}>
                                        <Glyphicon bsSize="large" glyph="trash" />
                                    </Button>
                                </Col>
                                {index == this.state.diagDetails.length - 1 &&
                                <Col xs={1}>
                                    <Button style={{ marginTop: '2.5rem' }} onClick={() => this.handleAddRow(index)} disabled={index !== this.state.diagDetails.length - 1 || this.isEmpty(this.state.diagDetails[index]?.diagnosisCD)}>
                                        <Glyphicon bsSize="large" glyph="plus" />
                                    </Button>
                                </Col>
                                }
                            </Row>
                    }
                    {!this.state.isOpenForEdit && 
                        <Row style={borderStyle} key={`diag-border-`+index}>
                            <Col xs={12}></Col>
                        </Row>
                    }
                    </>
                ))}
            </div>
        );
    }
    
    renderModalBody = (isClaimsOrRiskMitigationDiagnosis, hasValidationErrors, hasPostValidationErrors) => {
        return (
            <div>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Provider Type</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.providerTypeID} onChange={this.handleProviderTypeChange}
                                disabled={isClaimsOrRiskMitigationDiagnosis} onBlur={(e) => this.onFieldBlur(e, 'providerTypeID')}>
                                <option value="">Select</option>
                                {
                                    this.props.providerTypeData &&
                                    this.props.providerTypeData.referenceData.map((item) => {
                                        return <option key={item.value} value={item.value}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">DOS</ControlLabel>
                            <DatePicker value={this.state.dateOfServiceFrom} onChange={this.handleDateChange('dateOfServiceFrom')} style={{ zIndex: 'auto' }}
                                disabled={isClaimsOrRiskMitigationDiagnosis} onBlur={(e) => this.onFieldBlur(e, 'dateOfServiceFrom')}
                                onFocus={() => this.dateFieldTouched('dateOfServiceFrom')} ref={(ref) => this.datepickerFrom = ref}  />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">DOS Thru</ControlLabel>
                            <DatePicker value={this.state.dateOfServiceTo} onChange={this.handleDateChange('dateOfServiceTo')} style={{ zIndex: 'auto' }}
                                disabled={this.state.disableDOSThru || isClaimsOrRiskMitigationDiagnosis} onBlur={(e) => this.onFieldBlur(e, 'dateOfServiceTo')}
                                onFocus={() => this.dateFieldTouched('dateOfServiceTo')} ref={(ref) => this.datepickerTo = ref} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormGroup bsClass="form-group provider-typeahead-form-group">
                            <ControlLabel style={{ marginRight: '1rem' }}>Providers via Claims + DOS</ControlLabel>
                            <Select value={this.state.claimsProviderInputSet ? this.state.selectedProvider : ''}
                                valueKey="providerMasterID" labelKey="label" options={this.state.claimsProviders}
                                onBlur={(e) => this.onFieldBlur(e, 'providerMasterID')}
                                onChange={this.handleClaimsProviderChange} disabled={this.isClaimsProviderDisabled()} />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup bsClass="form-group provider-typeahead-form-group">
                            <ControlLabel style={{ marginBottom: '0.4rem', marginRight: '1rem' }}>Search All Providers</ControlLabel>
                            <Row style={{ margin: 0 }}>
                                <Col xs={10} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                                    <ProviderTypeaheadSearchInput
                                        value={!this.state.claimsProviderInputSet ? this.state.selectedProvider : ''}
                                        autoFocus={!this.props.editMode} disabled={this.isProviderTypeaheadDisabled()}
                                        onBlur={this.onFieldBlur} handleProviderChange={this.handleProviderTypeaheadChange}
                                        getProviderDropdownLabel={this.props.getProviderDropdownLabel} toast={this.props.toast}
                                        memberID={this.props.memberID} />
                                </Col>
                                <Col xs={2} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                                    <Button bsSize="small" style={{ height: '3.6rem', minWidth: '4rem', width: '100%' }} onClick={() => this.toggleProviderSearchModal()}
                                        disabled={this.isProviderTypeaheadDisabled()}>
                                        <Glyphicon glyph="search" />
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormGroup bsClass="form-group provider-typeahead-form-group">
                            <ControlLabel style={{ marginBottom: '0.4rem', marginRight: '1rem' }}>Secondary Provider</ControlLabel>
                            <Row style={{ margin: 0 }}>
                                <Col xs={10} style={{ paddingRight: '0.2rem', paddingLeft: '0rem' }}>
                                    {/* todo: make onBlur optional in <ProviderTypeaheadSearchInput /> */}
                                    <ProviderTypeaheadSearchInput value={this.state.selectedSecondaryProvider}
                                        autoFocus={!this.props.editMode} disabled={this.isAutoClaimsLinkingDisabled()}
                                        onBlur={() => { return; }} handleProviderChange={this.handleSecondaryProviderTypeaheadChange}
                                        getProviderDropdownLabel={this.props.getProviderDropdownLabel} toast={this.props.toast}
                                        memberID={this.props.memberID} />
                                </Col>
                                <Col xs={2} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                                    <Button bsSize="small" style={{ height: '3.6rem', minWidth: '4rem', width: '100%' }} onClick={() => this.toggleSecondaryProviderModal()}
                                        disabled={this.isAutoClaimsLinkingDisabled()}>
                                        <Glyphicon glyph="search" />
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    {this.state.isOpenForEdit &&
                    <Col xs={5}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }}>Claim Number</ControlLabel>
                            <Row>
                                <Col xs={8} style={{ paddingRight: '0.2rem' }}>
                                    <FormControl type="text" className="uppercaseText" value={this.state.diagDetails[0].claimNumber}
                                        onChange={(e) => this.handleClaimNumberChange(e,0)} onBlur={(e) => this.onFieldBlur(e, 'claimNumber')}
                                        disabled={isClaimsOrRiskMitigationDiagnosis || !this.isAutoClaimsLinkingDisabled()} />
                                </Col>
                                <Col xs={4} style={{ paddingRight: '0rem', paddingLeft: '0.5rem' }}>
                                    {this.renderClaimLinkButton()}
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    }
                </Row>
                {this.renderDiagRows(isClaimsOrRiskMitigationDiagnosis)}
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Source</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.sourceID} onChange={this.handleSourceTypeChange}
                            disabled={this.props.isRiskMitigation} onBlur={(e) => this.onFieldBlur(e, 'sourceID')}>
                                <option value="">Select</option>
                                {
                                    this.props.sourceData &&
                                    this.props.sourceData.referenceData.map((item) => {
                                        return <option key={item.value} value={item.value}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }}>Comments</ControlLabel>
                            <Select
                                multi
                                value={this.state.diagnosisComments}
                                options={this.state.commentsDropdownValues}
                                onChange={this.handleCommentsChange} />
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className={this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString()
                                ? "requiredField" : ""}>Image ID</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.medicalRecordImageID} onChange={this.handleImageIDChange}
                                onBlur={this.imageIDBlur}>
                                <option value="">Select</option>
                                {
                                    this.props.imageRecords &&
                                    this.props.imageRecords.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.title}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {this.state.isOpenForEdit &&
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className={this.state.medicalRecordTypeID === MedicalRecordType.Scan.toString()
                                || this.state.medicalRecordTypeID === MedicalRecordType.Onsite.toString() ? "requiredField" : ""}>Pages</ControlLabel>
                            <FormControl type="text" value={this.state.diagDetails[0].pages} onChange={(e) => this.handlePageChange(e,0)}
                                onBlur={(e) => this.onFieldBlur(e, 'pages')} />
                        </FormGroup>
                    </Col>
                    }
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">Record Type</ControlLabel>
                            <FormControl componentClass="select" placeholder="Select" value={this.state.medicalRecordTypeID} onChange={this.handleMedicalRecordTypeChange}
                                onBlur={(e) => this.onFieldBlur(e, 'medicalRecordTypeID')}>
                                <option value="">Select</option>
                                {
                                    this.props.medicalRecordTypeData &&
                                    this.props.medicalRecordTypeData.referenceData.map((item) => {
                                        return <option key={item.value} value={item.value}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel style={{ marginRight: '1rem' }} className="requiredField">RAF</ControlLabel>
                            <FormControl componentClass="select" value={this.state.rafTypeID} onChange={this.handleRafTypeChange}>
                                {
                                    this.props.rafTypeData &&
                                    this.props.rafTypeData.referenceData.map((item) => {
                                        return <option key={item.value} value={item.value}>{item.description}</option>
                                    })
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    this.props.isRADV &&
                    <Row>
                        <Col xs={4}>
                            <FormGroup>
                                <ControlLabel style={{ marginRight: '1rem', display: 'block' }} className="requiredField">RADV Score</ControlLabel>
                                <Button bsStyle="default" onClick={this.toggleRADVModal} disabled={this.props.editQABool}>Add a score</Button>
                            </FormGroup>
                        </Col>
                        {
                            this.props.isQA && this.props.isRADV && this.props.editQABool &&
                            <div>
                                <Col xs={4}>
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem', display: 'block' }} className="requiredField">Evidence ID</ControlLabel>
                                        <FormControl componentClass="select" placeholder="Select" value={this.state.evidenceImageID} onChange={this.handleEvidenceIDChange}>
                                            <option value="">Select</option>
                                            {
                                                this.props.userUploadedImages &&
                                                this.props.userUploadedImages.map((item) => {
                                                    return <option key={item.id} value={item.id}>{item.title}</option>
                                                })
                                            }
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col xs={4}>
                                    {/* Evidence manage pdf modal */}
                                    <FormGroup>
                                        <ControlLabel style={{ marginRight: '1rem', display: 'block' }} >Evidence</ControlLabel>
                                        <Button bsStyle="default" onClick={() => this.toggleEvidenceModal()}>Manage PDF</Button>
                                    </FormGroup>
                                </Col>
                            </div>
                        }
                    </Row>
                }
            </div>
        )
    }

    renderModalTitle = () => {
        return !this.state.showClaimsDiagGeneration ? (
            <b>{this.props.editQABool ? 'Edit and Approve' : (this.props.editMode ? 'Edit Diagnosis' : 'Add Diagnosis')}</b>
        ) : (
            <b>Update Successful
                <Glyphicon glyph="ok" style={{ color: 'green', marginLeft: '1rem', marginRight: '1rem' }} />
            </b>
        )
    }

    renderClaimsDiagGeneration = () => {
        return (
            <div style={{ paddingTop: '1rem' }}>
                <span>{`${this.state.claimsDiagGenerationCount ? this.state.claimsDiagGenerationCount : 'No'}
                    additional claim ${this.state.claimsDiagGenerationCount !== 1 ? 'diagnoses have' : 'diagnosis has'}
                    been generated for this review.`}
                </span>
            </div>
        )
    }

    renderClaimLinkButton = () => {
       return this.state.loadingClaimLink ? (
            <div style={{ paddingTop: '1rem', }}>
                <Glyphicon className="spinning" glyph="cog" style={{ lineHeight: '14px', color: '#6c716c',
                    marginLeft: '0.7rem', fontSize: '20px' }} />
            </div>
        ) : (
            <div>
                <Button bsSize="small" style={{ height: '3.6rem', minWidth: '4rem' }}
                    onClick={() => this.getProviderGroupsAndClaimLink()}
                    disabled={this.isAutoClaimsLinkingDisabled() || !this.state.dateOfServiceFrom}>
                    <Glyphicon glyph="link" />
                </Button>
                {
                    this.state.claimLinkID &&
                    <Glyphicon bsSize="large" glyph="ok" style={{ color: 'green', paddingLeft: '1rem' }} />
                }
            </div>
        )
    }

    render() {
        const diagSaveObj = this.state.showEvidenceModal || this.state.showRADVModal ? this.getDiagSaveObj() : null;
        const isClaimsOrRiskMitigationDiagnosis = DiagnosisUtils.isClaimsOrRiskMitigationDiagnosis(this.props.editModalRow);
        const hasValidationErrors = this.state.validationErrorMessages && this.state.validationErrorMessages.length > 0;
        const hasPostValidationErrors = this.state.postValidationErrorMessages && this.state.postValidationErrorMessages.length > 0;
        return (
            <div>
                <Modal show={this.state.visible} className="diagFormModal" onHide={() => this.handleModalToggle()} keyboard bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title>
                        {this.renderModalTitle()}
                        {
                            this.props.member &&
                            <span><b> for {this.props.member.lastName}, {this.props.member.firstName} (DOB: {this.props.member.dob})</b></span>
                        }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Loader loaded={this.state.diagLoaded}>
                        {
                            !this.state.showClaimsDiagGeneration ?
                                this.renderModalBody(isClaimsOrRiskMitigationDiagnosis, hasValidationErrors, hasPostValidationErrors)
                                : this.renderClaimsDiagGeneration()
                        }
                        </Loader>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ textAlign: 'left' }}>
                            {
                                hasValidationErrors && this.state.inputTouched &&
                                this.state.validationErrorMessages.map(error => {
                                    return <div key={error.id} className="errorMessage">{error.errorText}</div>
                                })
                            }
                            {
                                hasPostValidationErrors &&
                                this.state.postValidationErrorMessages.map(error => {
                                    return <div key={error.id} className="errorMessage">{error.errorText}</div>
                                })
                            }
                            <br/>
                        </div>
                        {
                            !this.state.showClaimsDiagGeneration &&
                            <div style={{ float: 'left' }}>
                                {
                                    (!this.props.editQABool || !this.props.isQA) && !this.props.editMode &&
                                    <Button bsStyle="primary" disabled={!this.state.inputTouched || hasValidationErrors}
                                        onClick={() => this.addDiag()}>
                                        + Add Entry
                                    </Button>
                                }
                                {
                                    (!this.props.editQABool || !this.props.isQA) && this.props.editMode &&
                                    <Button bsStyle="primary" disabled={!this.state.inputTouched || hasValidationErrors}
                                        onClick={() => this.updateDiag()}>
                                        + Save Edited Entry
                                    </Button>
                                }
                                {
                                    this.props.editQABool &&
                                    <Button bsStyle="primary" onClick={() => this.updateDiag(this.props.isMD ? LineItemReviewStatus.MDConfirmed : LineItemReviewStatus.Confirmed, true)}>
                                        Save and Approve
                                    </Button>
                                }
                                <Button bsStyle="primary" onClick={this.toggleConfirmModal}>
                                    Cancel Entry
                                </Button>
                            </div>
                        }
                    </Modal.Footer>
                </Modal>
                <AlertDialog visible={this.state.showConfirmModal} handleModalToggle={this.toggleConfirmModal} title={this.confirmModalMessage}
                    renderBody={false} handleConfirm={this.handleConfirm} confirmLabel={"Yes"} cancelLabel={"No"} confirmStyle={"success"}
                    glyphicon={"ok"} dialogClassName={'modal-dialog-small-vertical-alignment'} />
                <EvidenceUploadModal visible={this.state.showEvidenceModal} handleModalToggle={this.toggleEvidenceModal} diagData={diagSaveObj}
                    medicalRecordReviewID={this.props.medicalRecordReviewID} radvEnrolleeID={this.props.radvEnrolleeID} />
                <ProviderSearchModal visible={this.state.showProviderModal} value={this.state.selectedProvider}
                    handleModalToggle={this.toggleProviderSearchModal} handleSelectSearchResult={this.handleProviderSearchModalSelect}
                    toast={this.props.toast} memberID={this.props.memberID} />
                <ProviderSearchModal visible={this.state.showSecondaryProviderModal} value={this.state.selectedSecondaryProvider}
                    handleModalToggle={this.toggleSecondaryProviderModal} handleSelectSearchResult={this.handleSecondaryProviderModalSelect}
                    toast={this.props.toast} memberID={this.props.memberID} />
                <RADVFormModal visible={this.state.showRADVModal} handleModalToggle={this.toggleRADVModal} row={diagSaveObj}
                    radvScores={this.props.radvScores} hccHierarchies={this.props.hccHierarchies} radvAuditEnrolleeHCCs={this.props.radvAuditEnrolleeHCCs}
                    setRADVScore={this.setRADVScore} setHCCHierarchy={this.setHCCHierarchy} handleRADVAuditHCCChange={this.handleRADVAuditHCCChange} />
            </div>
        );
    }
}

export default DiagnosisFormModal;