import React from 'react';
import axios from 'axios';
import { Button,Col, ControlLabel, FormGroup, Row, Glyphicon} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import AlertToastr from '../../Shared/AlertToastr';
import Loader from 'react-loader';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const currentUserProp = state.session.find(x => x.currentUser);
    return currentUserProp ? currentUserProp : {};
};

class SingleChartUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects : [],
            projectID : "",            
            reviewData : [],
            selectedReviewId: 0,
            toast: null,
            loading: false,
            uploaded:{}
        }
        this.fileUpload = React.createRef();
    }

    componentWillMount() {
        Promise.all([axios.get('/projects', {
            params: {
                prePopulatedReviews: true
            }
        })])
        .then((responses) => {
            this.setState({
                projects: responses[0].data
            });
        });
 
         let uploadedIds = JSON.parse(sessionStorage.getItem('uploadedIds')) || [];
         let uploadedStatus = {};
         uploadedIds.forEach((id)=>{
            uploadedStatus[id] = true
         })
         this.setState({
            uploaded:uploadedStatus
         })

        
    }

    formatDate = (cell, row)  => {
        return row ? row.memberDOB.split('T')[0] : '';
    }
    formatMemberName = (cell, row) => {      
        return row  ? `${row.memberLastName} ${row.memberFirstName}` : '';
    }
    formatProviderName = (cell, row) => {
        return row  ? `${row.providerFirstName} ${row.providerLastName}` : '';
    }
    formatReviewStatus = (cell, row)  => {
        return row  ? row.statusDescription  : '';
    }
    getReviews = () => 
    axios.get('/SingleFileUpload/GetCreatedIdentifiedReviewByProject', {
        params: {
            projectId: this.state.projectID
        }
    })
    .then((response) => {
        this.setState({ reviewData: response.data });
    })
    .catch((error) => {
        this.state.toast('error', "Could not load Review!", 'Error');
    });
    
    handleProjectChange = (e) => {
        this.setState({ projectID: e ? e.value : ''}, () => {
            if (e) {
                this.getReviews();
            }
        });
    }

    selectAndOpenModal = (reviewId) => {
        this.setState({selectedReviewId: reviewId});
        this.fileUpload.current.click();
    }

    handleClick = (event) => {
        event.target.value = null;
        this.blur();
      }


    storeUploadedIds = (id) => {
        let ids = JSON.parse(sessionStorage.getItem('uploadedIds')) || []
        if(!ids.includes(id)){
            ids.push(id);
            sessionStorage.setItem('uploadedIds',JSON.stringify(ids))
        }
    }

    handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            if (fileType === 'application/pdf' || fileExtension === 'pdf') {
                this.setState({ loading: true });
                const selectedReview  = this.state.reviewData.find(x=> x.medicalRecordReviewID === this.state.selectedReviewId);
                const chartManifestJson = JSON.stringify({
                    mrrID: selectedReview.medicalRecordReviewID,
                    providerID: selectedReview.providerID,
                    chaseID: selectedReview.chaseId,
                    dosFrom: selectedReview.dateOfServiceFrom,
                    projectID: this.state.projectID,
                    lob: localStorage.getItem('lineOfBusiness'),
                    userId:this.props.currentUser.userID
                });
                const data = new FormData();
                data.append('file', file);

                axios.post(`/SingleFileUpload/FileUpload?serializedChartManifestDetails=${chartManifestJson}`, data)
                .then((response) => {
                    this.setState((prevState)=>({
                        uploaded:{...prevState.uploaded, [this.state.selectedReviewId]:true}
                    }))
                    this.storeUploadedIds(this.state.selectedReviewId)
                    this.state.toast('success', 'File uploaded successfully!', '');
                    this.getReviews();
                })
                .catch((error) => {
                    console.log(error)
                    this.setState((prevState)=>({
                        uploaded:{...prevState.uploaded, [this.state.selectedReviewId]:false}
                    }))
                    if (error?.response?.data?.message) {
                        this.state.toast('error', error.response.data.message, 'Error');
                    }
                    else {
                        this.state.toast('error', 'An error occured!', 'Error');
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                    this.blur();
                });
                
            } else {
                this.setState((prevState)=>({
                    uploaded:{...prevState.uploaded, [this.state.selectedReviewId]:false}
                }))
                this.state.toast('error', `Cannot upload file of ${fileExtension} type`, 'Error');
                return;
            }
        }
    }
    
    setAddToast = (addToast) => {
        this.setState({ toast: addToast });
    }

    renderEditButton = (cell, row) => {
        return (
            <Button disabled={this.state.uploaded[row.medicalRecordReviewID]==true} onClick={() => this.selectAndOpenModal(row.medicalRecordReviewID)}>
                Upload
            </Button>
        );       
    }
    render() {
        return (
            <React.Fragment>
                
                <Row style={{ marginTop: '1rem' }}>
                    <Col xs={4}>
                        <FormGroup>
                            <ControlLabel>Project</ControlLabel>
                            <Select options={this.state.projects.map((item) => {
                                    return {
                                        label: item.projectName,
                                        value: item.projectID
                                    }
                                })} value={this.state.projectID} onChange={this.handleProjectChange} />
                        </FormGroup>
                        
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <BootstrapTable data={this.state.reviewData} hover pagination>
                            <TableHeaderColumn dataField="ChaseId" dataAlign="left" dataSort isKey hidden />
                            <TableHeaderColumn dataField="medicalRecordReviewID" dataAlign="left" dataSort width="16%" tdStyle={{ overflow: 'inherit' }}>Review Id</TableHeaderColumn>
                            <TableHeaderColumn dataField="statusDescription" dataFormat={this.formatReviewStatus} dataAlign="left" dataSort width="20%">Review Status</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberLastName" dataFormat={this.formatMemberName} dataSort >Member Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="memberDOB" dataFormat={this.formatDate} dataAlign="left" dataSort>Member DOB</TableHeaderColumn>
                            <TableHeaderColumn dataField="providerFirstName" dataFormat={this.formatProviderName} dataAlign="left" dataSort>Provider Full Name</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="left" dataFormat={this.renderEditButton}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
                <input ref={this.fileUpload} type="file" accept='application/pdf' datatype='' onChange={this.handleUpload} onClick={this.handleClick}
                    name="Upload" style={{ paddingLeft: '3rem', visibility: 'hidden' }} />
                <AlertToastr setAddToast={this.setAddToast} />
                <Loader loaded={!this.state.loading} />
            </React.Fragment>
        )
    }
}


export default connect(
    mapStateToProps
)(SingleChartUpload);